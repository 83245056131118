.about {
  display: flex;
  height: 60vh;
  position: relative;
  align-items: center;
}
.about-full {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 0rem 36rem;
  width: 270px;
}
.about-head {
  width: 400px;
  height: 100px;
  font-weight: 700;
  font-size: 30px;
  display: flex;
  text-align: center;
  padding: 0 4rem;
  justify-content: center;
  width: 270px;
}
.about-para {
  width: 600px;
  height: 200px;
  font-weight: 300;
  font-size: 20px;
  display: flex;
  text-align: center;
  padding: 0 4rem;
}
.first-blur {
  height: 300px;
  width: 300px;
  background: #e9de81;
  filter: blur(180px);
  z-index: 1;
  position: absolute;
  left: 6rem;
}
.sec-blur {
    height: 300px;
    width: 300px;
    background: #CABAED;
    filter: blur(180px);
    z-index: 1;
    position: absolute;
    right:6rem;
  }

@media screen and (max-width: 576px) {
  .about {
    flex-direction: column;
    margin-top: 20rem;
  }
  .about-full {
    flex-direction: column;
  }
  .about-head {
    font-size: 30px;
  }
  .about-para {
    font-size: 15px;
    width: 200px;
    height: 150px;
    width: 300px;
  }
  .about-full {
    padding-right: -20rem;
    padding: 0;
  }
  .blur-two {
    height: 150px;
    width: 150px;
    background: #e9de81;
    filter: blur(120px);
    z-index: 1;
    position: absolute;
    left: 6rem;
  }
  .first-blur{
    display: none;
  }
  .sec-blur{
    display: none;
  }
}

.logos{
    display: flex;
    margin-top:-8rem;
}
.logo-full{
    display: flex;
    justify-content: space-evenly;
    gap:8rem;
    padding-left:7rem;
}
@media screen and (max-width:576px) {
    .logos{
        flex-direction: column;
        transform:scale(0.2);
        padding:0rem 5rem;
        margin-top: -25rem;
    }
}
.partner{
    height:60vh;
    position: relative;
    display: flex;
    align-items: center;
   
}

.partner-full{
  
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    position: relative;
    padding:0 20rem;
}
.partner-head{
    width:200px;
    height:50px;
    font-weight:700;
    font-size: 40px;
    text-align: center;
   
}
.partner-para{
    width:790px;
    height:120px;
    font-weight: 300;
    font-size: 20px;
    text-align: center;
    margin-top:2rem;
}
.sixth-blur{
    height: 300px;
    width: 300px;
    background: #AEEBF3;
    filter: blur(100px);
    z-index: 1;
    position: absolute;
    left:6rem;
}
@media screen and (max-width: 576px) {
    .partner{
        flex-direction: column;
        margin-top: 30rem;
        
    }
    .partner-full{
        flex-direction: column;
        padding:0;
    }
    .partner-head{
        font-size: 30px;
    }
    .partner-para{
        font-size:15px;
        width:200px;
        height:150px;
        width:300px;
    }
    .blur-nine{
        height:150px;
        width:150px;
        background:#AEEBF3;;
        filter: blur(120px);
        z-index:1;
        position:absolute;
       left:-3rem;
       top:4rem;
    }
    .sixth-blur{
        display: none;
    }
}
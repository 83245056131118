.main-footer{
    height:70vh;
    position: relative;
    margin-top:5rem;
    
}
.main-2-footer{
    position: absolute;
    height:60vh;
    width:90vw;
    margin-top:2rem;
    margin-left:2rem;
    
}
.into-footer{
    position: absolute;
    height:40vh;
    width:30vw;
    margin:3rem;
    top:2rem;
    
    
}
.first-cont{
    height:15vh;
    width:20vw;
    position: absolute;
    left:4rem;
    top:1rem;
}
.first-cont>img{
    position: absolute;
    
}
.first-cont>span{
    position: absolute;
    left:6rem;
    top:2rem;
    font-size: 25px;
    font-weight: 600;
}
.sec-cont{
    height:80px;
    width:310px;
    position: absolute;
    top:8rem;
    left:4rem;
}
.third-cont{
    width:300px;
    position: absolute;
    top:15rem;
    left:4rem;

}
.third-cont>:hover{
    cursor: pointer;
    color:blue;
}
.third-cont>button{
    padding:10px 30px;
    border:none;
    border-radius: 10px;
    background: linear-gradient(135deg, #EE9AE5 0%, #5961F9 100%);
    font-size: 25px;
    font-weight:600;
    color:white;

}
.sec-footer{
   height:300px;
   width:300px;
   position: absolute;
   margin-left:40rem;
   margin-top:5rem;
}
.sec-footer>li:hover{
    cursor: pointer;
    color:palevioletred;
}
.third-footer{
    height:300px;
    width:300px;
    position: absolute;
    margin-left:60rem;
    margin-top:5rem;
}
.sec-footer{
    list-style-type: none;
    width:200px;
    font-size:20px;
    font-weight:600;
    line-height: 3rem;

}
.Social{
    height:200px;
    width:800px;
    display: flex;
    left:10rem;
    position: relative;
    transform: scale(0.15);
    left:-20.5rem;
    top:-4rem;
    justify-content: space-evenly;
    gap:20rem;
    left:-19rem;
}
.Social>a:hover{
    cursor: pointer;
    
}
.third-footer>:nth-child(1){
    width:400px;
}
.Social>a{
    transform: scale(0.5);
    position: relative;
    left:20rem;
}
.tenth-blur{
    height: 300px;
    width: 300px;
    background: #6f4bd1;
    filter: blur(230px);
    z-index: 1;
    position: absolute;
    right:2rem;
}
@media screen and (max-width:576px){
    .main-footer{
       
        flex-direction: column;
        transform: scale(0.3);
        margin-left:-14rem;
        margin-top:-10rem;
        left:2rem;
    }
    .Social{
        height:500px;
        margin-top:-6rem;
        gap:20rem;
       
    }
    .sec-footer{
        left:-10rem;
    }
    .third-footer{
        left:-14rem;
    }
    .tenth-blur{
        display: none;
    }
    
}
.service{
    display: flex;
    height:60vh;
    position: relative;
    align-items: center;
    
   
}
.service-full{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding:0rem 35rem ;
    
}
.service-head{
    width:200px;
    height:200px;
    font-weight:700;
    font-size: 30px;
    display: flex;
    text-align: center;
    padding:0 4rem;
    justify-content: center;
    align-items: center;
}
.service-para{
    width:200px;
    height:200px;
    font-weight:300;
    font-size: 20px;
    display: flex;
    text-align: center;
    padding:0 4rem;
}
.third-blur{
    height: 200px;
    width: 200px;
    background: #CABAED;
    filter: blur(100px);
    z-index: 1;
    position: absolute;
    left:25rem;

}
@media screen and (max-width:576px) {

    .service{
        flex-direction: column;
        margin-top:30rem;
    }
    .service-full{
        flex-direction: column;
        padding:0;
       
    }
    .service-head{
        text-align: center;
    }
    
    .blur-three{
        height:150px;
        width:150px;
        background: #CABAED;
        filter: blur(100px);
        z-index:1;
        position:absolute;
       left:-4rem;
    }
    .third-blur{
        display:none;
    }
}
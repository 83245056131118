.exchange{
    position:relative;
    height:70vh;
    display: flex;
    margin-top: 5rem;

}
.right-exchange{
    position: relative;
    display: flex;
    flex:1 1 ;
    justify-content: center;
    align-items: center;
    padding:0 8rem;
 
}

.left-exchange{
    position: relative;
    display: flex;
    flex:1 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:2rem;
    padding:0 2.5rem;
}
.left-heading-E{
    height:60px;
    width:450px;
    font-size:40px;
    font-weight:700;
    text-align: start;

}
.left-para-E{
    height:165px;
    width:440px;
    font-size: 20px;
    text-align: start;
    font-weight: 300;
}
.left-main-E{
    height:114px;
    width:280px;
    font-size: 20px;
    text-align: start;
    font-weight: 600;
    position: relative;
    left:-5rem;
}
.eighth-blur{
    height: 300px;
    width: 300px;
    background: #F2AEF3;
    filter: blur(200px);
    z-index: 1;
    position: absolute;
    left:2rem;
}
@media screen and (max-width:576px) {
    .exchange{
        margin-top:10rem;
        flex-direction: column-reverse;
        margin-top: 43rem;
       top:3rem;
    }
    .right-exchange>img{
        transform: scale(0.5);
        position: relative;
        top:5rem;
    }
    .left-heading-E{
        font-size: xx-large;
        text-align: center;
        width:280px;
    }
    .left-para-E{
        font-size: 20px;
        text-align: center;
    }
    .left-para-E{
        height:250px;
        width:325px;
        text-align: center;
    }
    .left-main-E{
        font-size: 15px;
        text-align: center;
        margin-top: -5rem;
        width:280px;
        left:1rem;
    }
    .blur-eight{
        height:150px;
        width:150px;
        background:#F3AEB3;;
        filter: blur(120px);
        z-index:1;
        position:absolute;
       left:5rem;
       top:4rem;
    }
    .eighth-blur{
        display: none;
    }
}
.giveaway{
    position:relative;
    height:70vh;
    display: flex;
    margin-top: 5rem;

}
.left-giveaway{
    position: relative;
    display: flex;
    flex:1 1 ;
    justify-content: center;
    align-items: center;
 
}
.left-giveaway>img{
    padding:0rem 8rem;
}
.right-giveaway{
    position: relative;
    display: flex;
    flex:1 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:2rem;
}
.right-heading{
    height:60px;
    width:450px;
    font-size:40px;
    font-weight:700;
    text-align: start;

}
.right-para{
    height:165px;
    width:440px;
    font-size: 20px;
    text-align: start;
    font-weight: 300;
}
.right-main{
    height:114px;
    width:313px;
    font-size: 20px;
    text-align: start;
    font-weight: 600;
    position: relative;
    left:-4rem;
}
.fourth-blur{
    height: 300px;
    width: 300px;
    background: #D7E773;
    filter: blur(190px);
    z-index: 1;
    position: absolute;
    right:6rem;
}
@media screen and (max-width:576px) {
    
    .giveaway{
        flex-direction: column;
        margin-top: -10rem;
    }
    
    .left-giveaway{
        transform: scale(0.5);
    }
    .right-giveaway{
        margin-left: 1rem;
        
    }
    .right-heading{
        font-size:xx-large;
        text-align: center;
        width:280px;
    }
    .right-para{
        height:250px;
      width:325px;
        text-align: center;
    }
    .right-main{
        font-size: 15px;
        text-align: center;
        margin-top: -5rem;
        width:280px;
        left:0.5rem;
    }
    .left-giveaway>img{
        padding: 0;
    }
    .blur-four{
        height:150px;
        width:150px;
        background:#D7E773;;
        filter: blur(120px);
        z-index:1;
        position:absolute;
       left:5rem;
       top:4rem;
    }
    .fourth-blur{
        display: none;
    }
}  
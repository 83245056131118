.intro{
    height:60vh;
    display: flex;
   margin-top:3rem;
    position: relative;
}

.intro-full{
    display: flex;
   
    position: relative;
   padding:0 1rem;
  background: linear-gradient(135deg, #EEAD92 0%, #6018DC 100%);
  border-radius: 30px 30px 229px 30px;
  color:white;
}
.intro-left{ 
    display: flex; 
  flex:1 1;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap:2rem;
  
   

}
.intro-right{
    position: relative;
    display: flex;
    flex:1 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:2rem;
}
.intro-head{
    height:120px;
    width:400px;
    font-size:40px;
    font-weight:700;
    text-align: start;
    padding:0rem 4rem;
}
.intro-para{
    height:80px;
    width:400px;
    font-size:20px;
    font-weight:300;
    text-align: start;
}
.intro-right>:nth-child(1){
    padding:0rem 8rem;
}

.apply-btn-1{
    display: none;
}
@media  screen and (max-width: 576px) {
    .intro{
        flex-direction: column;
       
    }

    .intro-full{
        flex-direction: column;
        border-radius: 30px 30px 30px 30px;
        height:40rem;
        
    }
    .intro-head{
        font-size: 25px;
        margin-top:2rem;
        height:100px;
        width:200px;
        text-align: center;
        
    }
    .intro-para{
        font-size:15px;
        height:100px;
        width:250px;
        text-align: center;
        margin-top:-3rem;
    }
    .intro-right{
        transform: scale(0.8);
        width:300px;
        height:200px;
        margin-top:-3rem;
    }
    .intro-right>:nth-child(1){
        padding:0;
        transform: scale(0.9);
        
    }
    .apply-btn-1{
        display:inline ;

    }
    
    .apply-btn-1>button{
        width:130px;
        height:50px;
        border:none;
        border-radius: 10px;
        background: linear-gradient(135deg, #EE9AE5 0%, #5961F9 100%);
        color:white;
        font-size: 20px;
        font-weight: 600;
        
     }
    .apply-btn-1>button:hover{
        cursor:pointer;
        color:black;
    }
}
.launchpad{
    position:relative;
    height:70vh;
    display: flex;
    margin-top: 5rem;

}
.left-launchpad{
    position: relative;
    display: flex;
    flex:1 1 ;
    justify-content: center;
    align-items: center;
 
}
.left-launchpad>img{
    padding:0rem 8rem;
}
.right-launchpad{
    position: relative;
    display: flex;
    flex:1 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:2rem;
}
.right-heading-L{
    height:60px;
    width:450px;
    font-size:40px;
    font-weight:700;
    text-align: start;
   
}
.right-para-L{
    height:165px;
    width:440px;
    font-size: 20px;
    text-align: start;
    font-weight: 300;
}
.right-main-L{
    height:114px;
    width:280px;
    font-size: 20px;
    text-align: start;
    font-weight: 600;
    position: relative;
    left:-5rem;
    list-style-type: circle;
}
.nineth-blur{
    height: 300px;
    width: 300px;
    background: #F3AEB3;
    filter: blur(230px);
    z-index: 1;
    position: absolute;
    right:4rem;
}
@media screen and (max-width:576px) {
    
    .launchpad{
        flex-direction: column;
        margin-top: -10rem;
        top:7rem;
    }
    
    .left-launchpad{
        transform: scale(0.5);
    }
    .right-launchpad{
        margin-left: 1rem;
        position: relative;
        top:-3rem;
        
    }
    .right-heading-L{
        font-size:xx-large;
        text-align: center;
        width:280px;
    }
    .right-para-L{
        height:250px;
      width:325px;
        text-align: center;
    }
    .right-main-L{
        font-size: 15px;
        text-align: center;
        margin-top: -5rem;
        width:280px;
        position: relative;
        left:0.5rem;
    }
    .blur-four{
        height:150px;
        width:150px;
        background:#D7E773;;
        filter: blur(120px);
        z-index:1;
        position:absolute;
       left:5rem;
       top:4rem;
    }
    .nineth-blur{
        display: none;
    }
}  
.ama{
    position:relative;
    height:70vh;
    display: flex;
    margin-top: 5rem;
    
}
.right-ama{
    position: relative;
    display: flex;
    flex:1 1 ;
    justify-content: center;
    align-items: center;
    padding:0 8rem;
 
}

.left-ama{
    position: relative;
    display: flex;
    flex:1 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:2rem;
    padding:0 2.5rem;
}
.left-heading-A{
    height:60px;
    width:450px;
    font-size:40px;
    font-weight:700;
    text-align: start;

}
.left-para-A{
    height:165px;
    width:440px;
    font-size: 20px;
    text-align: start;
    font-weight: 300;
}
.left-main-A{
    height:114px;
    width:300px;
    font-size: 20px;
    text-align: start;
    font-weight: 600;
    position: relative;
    left:-4.5rem;
}
.fifth-blur{
    height: 300px;
    width: 300px;
    background: #ABE4BB;
    filter: blur(180px);
    z-index: 1;
    position: absolute;
    left:6rem;
}
@media screen and (max-width:576px) {
    .ama{
        margin-top:10rem;
        flex-direction: column-reverse;
        margin-top: 37rem;
    }
    .right-ama>img{
        transform: scale(0.5);
        margin-left: -2.5rem;
        position: relative;
        top:2rem;
    }
    .left-heading-A{
        font-size: xx-large;
        text-align: center;
        width:280px;
    }
    .left-para-A{
        font-size: 20px;
        text-align: center;
    }
    .left-para-A{
        height:250px;
        width:325px;
        text-align: center;
    }
    .left-main-A{
        font-size: 15px;
        text-align: center;
        margin-top: -5rem;
        width:280px;
        left:1rem;
        top:5rem;
    }
    .blur-six{
        height:150px;
        width:150px;
        background:#AEEBF3;;
        filter: blur(80px);
        z-index:1;
        position:absolute;
       left:5rem;
       top:4rem;
    }
    .fifth-blur{
        display: none;
    }
}
.agency{
    display: flex;
    position: relative;
    height:50vh;
    justify-content: center;
    align-items: center;
}
.agency-full{
    display: flex;
    gap:10rem;
    position: absolute;
    left:10rem;
    
}
.agency-one{
    width:270px;
    height:270px;
    border-radius: 31px;
    box-shadow: 5px 4px 50px rgba(0,0,0,0.1);
    position: relative;
}
.agency-two{
    width:270px;
    height:270px;
    border-radius: 31px;
    box-shadow: 5px 4px 50px rgba(0,0,0,0.1);
}
.agency-three{
    width:270px;
    height:270px;
    border-radius: 31px;
    box-shadow: 5px 4px 50px rgba(0,0,0,0.1);
}
.agency-one>h3{
    width:150px;
    height:50px;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    position: absolute;
    margin-left: 3.5rem;;
    margin-top: -0.5rem;
}
.agency-one>img{
    margin-left:2rem;
}
.agency-two>img{
  margin-left: 2rem;
  margin-top: -2rem;
}
.agency-two>h3{
    width:150px;
    height:50px;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    position: absolute;
    margin-left: 3.5rem;;
    margin-top: -3rem;
}
.agency-three>img{
    margin-left: 0.5rem;
   
  }
  .agency-three>h3{
    width:100px;
    height:50px;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    position: absolute;
    margin-left: 5rem;;
    margin-top: -1rem;
}
@media screen and (max-width:576px) {
    
    .agency{
        flex-direction: column;
        margin-top:15rem;
        
        
    }
    .agency-full{
        flex-direction: column;
        position:static;
    }
    .blur-four{
        height:150px;
        width:150px;
        background:#ABE4BB;;
        filter: blur(120px);
        z-index:1;
        position:absolute;
       left:6rem;
    }
    .blur-five{
        height:150px;
        width:150px;
        background:#ABE4BB;;
        filter: blur(120px);
        z-index:1;
        position:absolute;
       left:6rem;
    }
    
    
}
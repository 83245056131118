.partnership{
    position:relative;
    height:70vh;
    display: flex;
    margin-top: 5rem;

}
.left-partnership{
    position: relative;
    display: flex;
    flex:1 1 ;
    justify-content: center;
    align-items: center;
 
}
.left-partnership>img{
    padding:0rem 8rem;
}
.right-partnership{
    position: relative;
    display: flex;
    flex:1 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:2rem;
}
.right-heading-P{
    height:60px;
    width:450px;
    font-size:40px;
    font-weight:700;
    text-align: start;

}
.right-para-P{
    height:165px;
    width:440px;
    font-size: 20px;
    text-align: start;
    font-weight: 300;
}
.right-main-P{
    height:114px;
    width:300px;
    font-size: 20px;
    text-align: start;
    font-weight: 600;
    position: relative;
    left:-4.5rem;
    
}
.seventh-blur{
    height: 300px;
    width: 300px;
    background: #6f4bd1;
    filter: blur(230px);
    z-index: 1;
    position: absolute;
    right:2rem;
}
@media screen and (max-width:576px) {
    
    .partnership{
        flex-direction: column;
        margin-top:-7rem;;
    }
    
    .left-partnership{
        transform: scale(0.5);
    }
    .left-partnership>img{
      
        position: relative;
        top:15rem;
    }
    .right-partnership{
        margin-left: 1rem;
        top:5rem;
        
    }
    .right-heading-P{
        font-size:xx-large;
        text-align: center;
        width:280px;
    }
    .right-para-P{
        height:250px;
      width:325px;
        text-align: center;
    }
    .right-main-P{
        font-size: 15px;
        text-align: center;
        margin-top: -5rem;
        width:400px;
        width:280px;
        left:0.5rem;
    }
    .blur-seven{
        height:150px;
        width:150px;
        background:#F2AEF3;;
        filter: blur(120px);
        z-index:1;
        position:absolute;
       left:5rem;
       top:4rem;
    }
    .seventh-blur{
        display: none;
    }
}  
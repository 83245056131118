.nav{
    display: flex;
    height:10vh;
    top: 0rem;
    width: 100%;
   
    justify-content: space-between;
    position:fixed;
    z-index: 9;
}
.n-list{
    display: flex;
    
}
.left-nav{
    display: flex;
    flex:3;
   
 
}
.n-list{
    list-style-type: none;
    gap:4rem;
    padding-left:10rem;
    padding-right: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.n-list>:hover{
    cursor:pointer;
    color:plum;
}
.n-list>li{
    display: flex;
    justify-content: center;
    
}

.right-nav{
    flex:1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.p-name{
    display: flex;
}
.p-name>div{
    display: flex;
    justify-content: center;
    align-items: center;
    

}
.p-name>:nth-child(1){
    transform: scale(0.7);
}
.p-name>div>span{
    width:200px;
    height:50px;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
    margin-top:1rem;
    margin-left: -1rem;
}
.apply-btn>button{
   width:150px;
   height:50px;
   border:none;
   border-radius: 10px;
   background: linear-gradient(135deg, #EE9AE5 0%, #5961F9 100%);
   color:white;
   font-size: 25px;
   font-weight: 600;
   
}
.apply-btn{
    padding:0 6rem;
     
}
.apply-btn>button:hover{
    cursor:pointer;
    color:black;
}
.menu-png{
    display: none;
}
.n-list-1{
    display: none;
}
@media  screen and (max-width: 576px) {

    .nav{
           
            position: fixed;
            top: 1.5rem;;
            width: 100%;
            z-index: 9;

      
    }
    .p-name{
        transform: scale(0.8);
        margin-left:-1rem;
        height:90px;
        width:90px;
        margin-top:-1.5rem;
    }
    .apply-btn{
       display: none;
    }
    .n-list{
        display: none;
       
        
    }
    .n-list>li{
          margin-top: -2rem;
    }
    .n-list>:hover{
        color:white;
    }
    .menu-png{
        display: inline;
        position: fixed;
       z-index: 99;
       background-color:#d4b0e9;
       padding:0.5rem;
       width:1.5rem;
       height:1.5rem;
       margin-top: -3rem;
       border-radius: 5px;
       right:5rem;
    }
    .n-list-1{
        display: flex;
        position: fixed;
        right:2rem;
        z-index: 99;
        flex-direction: column;
        background: linear-gradient(135deg, #EE9AE5 0%, #5961F9 100%);
        height:150px;
        margin-left:6rem;
        padding:3rem 2rem;
        gap:1.5rem;
        border-radius: 20px;
        list-style-type: none;
        margin-top:10rem;
        color:white;
        right:4.2rem;
    }
    .n-list-1>:hover{
        color:black;
    }
    
    
}